<div class="page-body-wrapper">
  <div class="flex-row">
    <rn-my-account-nav/>
    <div class="borrowing-history-content">
      <div class="borrowing-history-header flex-row flex-center-start">
        <a routerLink="/checkouts">
          <mat-icon>arrow_back</mat-icon>
        </a>
        <div class="mat-headline-4">Borrowing History</div>
      </div>
      <div class="mobile-table">
        <div *ngFor="let record of selectors.borrowingHistory | select:[]; let isLast = last; trackBy: trackHistoryById">
          <div class="flex-row flex-start-space-between">
            <div class="mobile-table-entry flex-col">
              <div>{{ selectors.titleFor(record) | select }}</div>
              <div>{{ selectors.materialTypeNameFor(record) | select | format: 'TITLE_CASE' }}</div>
              <div class="mat-caption">Checkout Date: {{ selectors.checkoutDateFor(record) | select | format: 'DATE_ONLY' }}</div>
              <div class="mat-caption">Returned: {{ selectors.checkinDateFor(record) | select | format: 'DATE_ONLY' }}</div>
            </div>
          </div>
          <mat-divider class="mobile-table-divider" *ngIf="!isLast"/>
        </div>
        <rn-pagination [class.loaded]="!selectors.awaitingAnyLoad"/>
      </div>
      <div class="full-table">
        <rn-table [tableDefinition]="borrowingHistoryTableDefinition"/>
      </div>
    </div>
  </div>
</div>
