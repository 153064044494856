import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MxSelector, MxSelectorCreator, NO_NAME_PREFIX} from '@store/store.types';
import {generateIndependentSelectorCreatorsFor, generateLoadStatusSelectorsFor, generateSelectorCreatorsDependentOn} from '@store/store.selector-generators';
import {CheckoutsState} from '@store/checkouts/checkouts.state';
import {BorrowingHistoryRecord, Checkout, CheckoutClaim, CHECKOUTS_FEATURE_KEY} from '@store/checkouts/checkouts.types';
import {FALLBACK_BORROWING_HISTORY_RECORD, FALLBACK_CHECKOUT, FALLBACK_CHECKOUT_CLAIM} from './checkouts.fallback';
import {FALLBACK_MATERIAL_TYPE} from '@store/common/common.fallback';

export const entireState: MxSelector<CheckoutsState> = createFeatureSelector(CHECKOUTS_FEATURE_KEY);

export namespace CheckedOut {

  const subState: MxSelector<CheckoutsState['checkedOut']> = createSelector(
    entireState,
    state => state.checkedOut
  );

  export const {
    loading,
    awaitingFirstLoad,
    awaitingAnyLoad,
    loaded,
  } = generateLoadStatusSelectorsFor(subState);

  export const checkouts: MxSelector<Checkout[]> = createSelector(
    subState,
    ({data}) => data
  );

  export const {
    dueDateFor,
    dueDaysFor,
    itemTitleFor,
    itemBarcodeFor,
    catalogRecordIdFor,
    itemMediaTypeFor,
    checkoutTimeFor,
    claimIdFor,
    holdExistsFor,
    imageUrlFor,
  } = generateIndependentSelectorCreatorsFor(FALLBACK_CHECKOUT, NO_NAME_PREFIX);

  export const overDueFor: MxSelectorCreator<[Checkout], boolean> =
    _checkout => createSelector(
      dueDaysFor(_checkout),
      (dueDays) => dueDays < 0
    );

  export const hasNoHolds: MxSelectorCreator<[Checkout], boolean> =
    checkout => createSelector(
      holdExistsFor(checkout),
      (holdExists) => !holdExists
    );

  export const hasNoClaim: MxSelectorCreator<[Checkout], boolean> =
    checkout => createSelector(
      claimIdFor(checkout),
      (claimId) => !(claimId > 0)
    );
}

export namespace Claims {

  const subState: MxSelector<CheckoutsState['claims']> = createSelector(
    entireState,
    state => state.claims
  );

  export const {
    loading,
    awaitingFirstLoad,
    awaitingAnyLoad,
    loaded,
  } = generateLoadStatusSelectorsFor(subState);

  export const claims: MxSelector<CheckoutClaim[]> = createSelector(
    subState,
    ({data}) => data
  );

  export const numberOfClaims: MxSelector<number> = createSelector(
    claims,
    (data) => data.length
  );

  export const {
    createdFor,
    checkoutFor,
  } = generateIndependentSelectorCreatorsFor(FALLBACK_CHECKOUT_CLAIM, NO_NAME_PREFIX);

  export const {
    itemTitleFor,
    itemBarcodeFor,
    catalogRecordIdFor,
    itemMediaTypeFor,
    imageUrlFor,
  } = generateSelectorCreatorsDependentOn(checkoutFor, FALLBACK_CHECKOUT, NO_NAME_PREFIX);
}

export namespace BorrowingHistory {

  const subState: MxSelector<CheckoutsState['borrowingHistory']> = createSelector(
    entireState,
    state => state.borrowingHistory
  );

  export const {
    loading,
    awaitingFirstLoad,
    awaitingAnyLoad,
    loaded,
  } = generateLoadStatusSelectorsFor(subState);

  export const borrowingHistory: MxSelector<BorrowingHistoryRecord[]> = createSelector(
    subState,
    ({data}) => data
  );

  export const {
    checkoutDateFor,
    titleFor,
    materialTypeFor,
    checkinDateFor,
  } = generateIndependentSelectorCreatorsFor(FALLBACK_BORROWING_HISTORY_RECORD, NO_NAME_PREFIX);

  export const {
    materialTypeNameFor,
  } = generateSelectorCreatorsDependentOn(materialTypeFor, FALLBACK_MATERIAL_TYPE, 'materialType');

}

