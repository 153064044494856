import {ChangeDetectionStrategy, Component, TrackByFunction} from '@angular/core';
import {trackById} from '@store/common/track-by.helpers';
import {CheckoutsSelectors} from '@store/store.selectors';
import {BorrowingHistoryRecord} from '@store/checkouts/checkouts.types';
import {TableDefinition} from '../../../table/table.component.types';

@Component({
  selector: 'rn-borrowing-history',
  templateUrl: './borrowing-history.component.html',
  styleUrls: ['./borrowing-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BorrowingHistoryComponent {
  public readonly selectors = CheckoutsSelectors.BorrowingHistory;
  public readonly trackHistoryById: TrackByFunction<BorrowingHistoryRecord> = trackById;

  public readonly borrowingHistoryTableDefinition: TableDefinition<BorrowingHistoryRecord, typeof CheckoutsSelectors.BorrowingHistory> = {
    defaultSelectorContainer: CheckoutsSelectors.BorrowingHistory,
    dataSource: 'borrowingHistory',
    columns: [
      {
        type: 'text',
        value: 'checkinDateFor',
        header: 'Returned',
        format: 'DATE_ONLY',
        headerCssClasses: 'table-column-width-small',
      },
      {
        type: 'text',
        value: 'titleFor',
        header: 'Item',
      },
      {
        type: 'text',
        value: 'materialTypeNameFor',
        header: 'Material Type',
        format: 'TITLE_CASE',
        headerCssClasses: 'table-column-width-small',
      },
      {
        type: 'text',
        value: 'checkoutDateFor',
        header: 'Checked Out',
        format: 'DATE_ONLY',
        headerCssClasses: 'table-column-width-small',
      },
    ]
  };
}
