<div class="tab-spacer hide-xs"></div>
<div class="warning-banner">
  <rn-account-status-warning context="banner"/>
</div>
<mat-tab-group class="profile-tabs"
               [(selectedIndex)]="tabIndex" (selectedIndexChange)="onTabChange($event)"
               dynamicHeight>
  <mat-tab>
    <ng-template mat-tab-label>
      <h3 class="hide-xs">Account</h3>
      <mat-icon class="hide-gt-xs">person</mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <rn-account/>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <h3 class="hide-xs">Balance & Payments</h3>
      <mat-icon class="hide-gt-xs">account_balance</mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <rn-balance-and-payments/>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <h3 class="hide-xs">Notification Preferences</h3>
      <mat-icon class="hide-gt-xs">mail</mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <rn-notification-preferences/>
    </ng-template>
  </mat-tab>
</mat-tab-group>
