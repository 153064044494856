import {NgModule} from '@angular/core';
import {OverlayModule} from '@angular/cdk/overlay';
import {SharedModule} from '../shared/shared.module';
import {BalanceAndPaymentsComponent} from './balance-and-payments/balance-and-payments.component';
import {InfoComponent} from './balance-and-payments/components/info.component';
import {BarcodePopoverComponent} from './balance-and-payments/components/barcode-popover.component';
import {ContactUsDialogComponent} from './balance-and-payments/components/contact-us-dialog.component';
import {ActivityFilterComponent} from './balance-and-payments/components/activity-filter.component';
import {MakeAPaymentFormComponent} from './balance-and-payments/components/make-a-payment-form.component';
import {AccountStatusContentComponent} from './balance-and-payments/components/account-status-content.component';
import {PaymentConfirmationDialogComponent} from './balance-and-payments/components/payment-confirmation-dialog/payment-confirmation-dialog.component';
import {PaymentSetupDialogComponent} from './balance-and-payments/components/payment-setup-dialog/payment-setup-dialog.component';
import {RvFormsModule} from '../shared/form-fields/rv-forms.module';

@NgModule({
  imports: [OverlayModule, SharedModule, RvFormsModule],
  exports: [
    BalanceAndPaymentsComponent,
    ActivityFilterComponent,
  ],
  declarations: [
    BalanceAndPaymentsComponent,
    InfoComponent,
    BarcodePopoverComponent,
    ContactUsDialogComponent,
    ActivityFilterComponent,
    MakeAPaymentFormComponent,
    AccountStatusContentComponent,
    PaymentConfirmationDialogComponent,
    PaymentSetupDialogComponent,
  ],
})
export class PatronBillingModule {
}
