import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SelectOptions} from '@store/common/common.types';
import {MatSelect} from '@angular/material/select';
import {trackByIndex, TrackByIndexFunction} from '@store/common/track-by.helpers';

@Component({
  selector: 'rv-select-form-field',
  template: `
    <rv-base-form-field [fieldName]="fieldName" [externalLabel]="externalLabel" [overrideLabel]="overrideLabel" [formGroup]="formGroup" [control]="control"
                        [enabled]="enabled" [subscriptSizing]="subscriptSizing">
      <mat-select [formControl]="control" [panelClass]="optionsPanelClass" [disableOptionCentering]="true">
        <mat-option *ngIf="includeUndefinedOption" [value]="undefined"/>
        <mat-option *ngFor="let option of options; trackBy: trackByIndex" [value]="option.value">
          {{ option.displayName }}
        </mat-option>
      </mat-select>
    </rv-base-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFormFieldComponent<ValueType> implements OnInit {
  @ViewChild(MatSelect) public matSelectComponent: MatSelect;

  @ViewChild(MatSelect, {read: ElementRef})
  public set matSelectElementRef(elementRef: ElementRef) {
    this.matSelectElement = elementRef?.nativeElement;
  }

  @Input() public fieldName: string;
  @Input() public overrideLabel: string;
  @Input() public formGroup: FormGroup;
  @Input() public options: SelectOptions<ValueType>;
  @Input() public includeUndefinedOption = true;
  @Input() public optionsPanelClass = '';
  @Input() public enabled = true;
  @Input() public externalLabel = false;
  @Input() public subscriptSizing: 'fixed' | 'dynamic' = 'dynamic';

  public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.
  public trackByIndex: TrackByIndexFunction = trackByIndex;

  private matSelectElement?: HTMLElement;

  public ngOnInit(): void {
    this.control = this.formGroup.get(this.fieldName) as FormControl;
  }

  public open(): void {
    this.matSelectComponent.open();

    setTimeout(
      () => this.matSelectElement?.focus()
    );
  }
}
