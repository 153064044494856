<ng-container *ngIf="patronItemRequest$ | async as request">
  <div class="section-top">
    <div>
      <h1 class="section-top-header">Item Request Details</h1>
      <ng-container *ngTemplateOutlet="statusPill; context: { status: request.status }"></ng-container>
    </div>
    <button
      *ngIf="(request.status === 'PENDING' || request.status === 'REQUESTED') && !isMobile"
      mat-raised-button
      color="primary"
      class="cancel-btn"
      (click)="deleteItemRequest(request)">
      Cancel Request
    </button>
  </div>

  <div class="section-content">
    <div class="section-content-left">

      <cdk-accordion *ngIf="request.hasLinkedCatalogRecord" class="accordion">
        <cdk-accordion-item #catalogItem="cdkAccordionItem" class="accordion-item" [expanded]="!isMobile" [disabled]="!isMobile">
          <div class="accordion-header" [ngStyle]="!isMobile && {'cursor': 'default'}" (click)="catalogItem.toggle()">
            <h2 [style.margin]="'0px'">Catalog Record</h2>
            <mat-icon *ngIf="isMobile" class="material-icons-outlined">
              {{ catalogItem.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </div>
          <div
            class="accordion-content"
            [style.display]="catalogItem.expanded ? '' : 'none'">
            <div [style]="{'display':'flex','column-gap':'20px'}">
              <img class="catalog-image" [src]="request.linkedCatalogRecord.imageUrl"/>
              <div>
                <div class="catalog-record-item">
                  <h4 class="catalog-record-subheader">Title:</h4>
                  <a href="/catalog-item/{{request.linkedCatalogRecord.id}}">
                    {{ request.linkedCatalogRecord.title }}
                  </a>
                </div>
                <div class="catalog-record-item">
                  <h4 class="catalog-record-subheader">Author:</h4>
                  {{ request.linkedCatalogRecord.author }}
                </div>
                <div class="catalog-record-item">
                  <h4 class="catalog-record-subheader">Available:</h4>
                  {{ request.linkedCatalogRecord.availability.availableCount }}
                </div>
                <div class="catalog-record-item">
                  <h4 class="catalog-record-subheader">Holds:</h4>
                  {{ request.linkedCatalogRecord.availability.onHoldCount }}
                </div>
              </div>
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>

      <cdk-accordion class="accordion">
        <cdk-accordion-item #details="cdkAccordionItem" class="accordion-item" [expanded]="!isMobile" [disabled]="!isMobile">
          <div class="accordion-header" [ngStyle]="!isMobile && {'cursor': 'default'}" (click)="details.toggle()">
            <h2 [style.margin]="'0px'">Submission Details</h2>
            <mat-icon *ngIf="isMobile" class="material-icons-outlined">
              {{ details.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </div>
          <div class="accordion-content" [style.display]="details.expanded ? '' : 'none'">
            <div class="details-item">
              <h4 class="details-subheader">Date Requested:</h4>
              {{ request.created | date }}
            </div>
            <div class="details-item">
              <h4 class="details-subheader">Title:</h4>
              {{ request.title }}
            </div>
            <div class="details-item">
              <h4 class="details-subheader">Author:</h4>
              {{ request.author }}
            </div>
            <div class="details-item">
              <h4 class="details-subheader">ISBN:</h4>
              {{ request.isbn || 'N/A' }}
            </div>
            <div class="details-item">
              <h4 class="details-subheader">Material Type:</h4>
              {{ request.materialTypeDesc || 'N/A' }}
            </div>
            <div class="details-item">
              <h4 class="details-subheader">Pickup Location:</h4>
              {{ request.pickupBranchName }}
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>
    </div>

    <cdk-accordion class="accordion accordion-messages">
      <cdk-accordion-item #messages="cdkAccordionItem" class="accordion-item" [expanded]="!isMobile" [disabled]="!isMobile">
        <div class="accordion-header" [ngStyle]="!isMobile && {'cursor': 'default'}" (click)="messages.toggle()">
          <h2 [style.margin]="'0px'">Messages</h2>
          <mat-icon *ngIf="isMobile" class="material-icons-outlined">
            {{ messages.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </mat-icon>
        </div>
        <div class="accordion-content accordion-content-messages" [style.display]="messages.expanded ? '' : 'none'">

          <div class="messages-view" #feed>
            <div class="messages-feed">
              <ng-container *ngFor="let comment of (comments$ | async); let i = index">

                <div *ngIf="shouldShowDivider(i)" class="messages-divider">
                  <hr/>
                  <span class="messages-divider-date">{{ comment.created | date : 'longDate' }}</span>
                </div>

                <div class="comment-wrapper" [ngClass]="{'comment-staff': comment.userId > 0}">
                  <div class="comment">
                    <div class="comment-bubble">
                      {{ comment.comment }}
                    </div>
                    <span class="comment-timestamp">{{ comment.created | date : 'shortTime' }}</span>
                  </div>
                  <div class="comment-initials">
                    <mat-icon *ngIf="comment.userId > 0; else initials" class="material-icons-outlined">person</mat-icon>
                    <ng-template #initials>
                      <span>{{ getPatronInitials() }}</span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="messages-input-wrapper" [formGroup]="messageForm">
            <input
              class="messages-input"
              formControlName="message"
              placeholder="Send message to librarian..."
              [matTooltip]="messageField.disabled ? 'You cannot send messages for requests that have been finalized.' : ''"
              matTooltipPosition="above"
              (keydown.enter)="postMessage()"
            />
            <button
              class="messages-send"
              mat-icon-button
              aria-label="Send message"
              matTooltip="Send message"
              matTooltipPosition="above"
              [disabled]="!messageForm.controls['message'].value"
              (click)="postMessage()">
              <mat-icon class="material-icons-outlined">send</mat-icon>
            </button>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    <button
      *ngIf="isMobile"
      mat-raised-button
      color="primary"
      class="cancel-btn"
      (click)="deleteItemRequest(request)">
      Cancel Request
    </button>
  </div>

</ng-container>

<ng-template #statusPill let-status="status">
  <div class="status-pending" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.PENDING">Pending</div>
  <div class="status-requested" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.REQUESTED">Requested</div>
  <div class="status-approved" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.APPROVED">Approved</div>
  <div class="status-declined" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.CANCELED">Canceled</div>
  <div class="status-declined" *ngIf="status == PATRON_ITEM_REQUEST_STATUS.DECLINED">Declined</div>
</ng-template>
