import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CheckoutsService} from '@store/checkouts/checkouts.service';
import {whenRouterHasNavigatedTo, withLatestFromSelectors} from '@store/common/effects.helpers';
import {catchError, map, mergeMap, of, switchMap} from 'rxjs';
import {CheckoutsActions} from './checkouts.actions';
import {RouterSelectors} from '@store/store.selectors';
import {PatronActions} from '@store/patron/patron.actions';

@Injectable({providedIn: 'root'})
export class CheckoutsEffects {

  private readonly actions: Actions = inject(Actions);
  private readonly service: CheckoutsService = inject(CheckoutsService);

  public loadItemsOutAndCheckoutClaimsOnRouterNavigated = createEffect(() =>
    this.actions.pipe(
      whenRouterHasNavigatedTo('CHECKOUTS'),
      mergeMap(() => [
        PatronActions.loadAccountStatus(),
        CheckoutsActions.loadCheckedOut(),
        CheckoutsActions.loadCheckoutClaims()
      ])
    )
  );

  public loadCheckedOut = createEffect(() =>
    this.actions.pipe(
      ofType(CheckoutsActions.loadCheckedOut),
      withLatestFromSelectors(RouterSelectors.queryParams),
      switchMap(([, queryParams]) => this.service.loadCheckedOut(queryParams).pipe(
        map(response => CheckoutsActions.loadCheckedOutSuccess(response)),
        catchError((error: unknown) => of(CheckoutsActions.loadCheckedOutFailure(error)))
      ))
    )
  );

  public loadCheckoutClaims = createEffect(() =>
    this.actions.pipe(
      ofType(CheckoutsActions.loadCheckoutClaims),
      switchMap(() => this.service.loadCheckoutClaims().pipe(
        map(response => CheckoutsActions.loadCheckoutClaimsSuccess(response)),
        catchError((error: unknown) => of(CheckoutsActions.loadCheckoutClaimsFailure(error)))
      ))
    )
  );

  public loadBorrowingHistoryOnRouterNavigated = createEffect(() =>
    this.actions.pipe(
      whenRouterHasNavigatedTo('BORROWING_HISTORY'),
      map(() => CheckoutsActions.loadBorrowingHistory())
    )
  );

  public loadBorrowingHistory = createEffect(() =>
    this.actions.pipe(
      ofType(CheckoutsActions.loadBorrowingHistory),
      withLatestFromSelectors(RouterSelectors.queryParams),
      switchMap(([, queryParams]) => this.service.loadBorrowingHistory(queryParams).pipe(
        map(response => CheckoutsActions.loadBorrowingHistorySuccess(response)),
        catchError((error: unknown) => of(CheckoutsActions.loadBorrowingHistoryFailure(error)))
      ))
    )
  );

}
