import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Location as BrowserLocation} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'rn-profile',
  templateUrl: './profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
      .warning-banner {
          max-width: var(--content-width);
          margin: 0 auto 0 auto;
          box-sizing: border-box;
      }

      mat-tab-group.profile-tabs {
          max-width: var(--content-width);
          margin: 0 auto 0 auto;
      }

      .tab-spacer {
          height: 32px;
      }
  `],
})
export class ProfileComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  tabIndex = 0;

  tabFragments = [
    'account',
    'billing',
    'notification-preferences'
  ];

  constructor(
    private browserLocation: BrowserLocation,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.tabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    const snapshot = this.route.snapshot;
    if (snapshot.queryParams['fragment']) {
      this.navToTabFragment(snapshot.queryParams['fragment']);
    }
    if (isNaN(this.tabIndex) || this.tabIndex < 0 || this.tabIndex > 4) {
      this.tabIndex = 0;
    }
    // catch navigation from top right user menu
    this.route.fragment.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((fragment) => {
      if (fragment !== this.tabFragments[this.tabIndex]) {
        this.navToTabFragment(fragment);
      }
    });
  }

  navToTabFragment(tab: string): void {
    if (!tab) {
      return;
    }
    const index = this.tabFragments.indexOf(tab);
    if (index !== -1) {
      this.tabIndex = index;
    }
  }

  onTabChange(selectedTabIndex: number): void {
    this.tabIndex = selectedTabIndex;
    // update the url
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        fragment: this.tabFragments[this.tabIndex],
      },
    });
  }
}
