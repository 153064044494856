import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'rn-my-account-nav',
  templateUrl: './my-account-nav.component.html',
  styleUrls: ['./my-account-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyAccountNavComponent {
}
